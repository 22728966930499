<template>
  <div>
    <NavBar class="mb-2"></NavBar>
    <b-container>
      <Loader v-if="loading"></Loader>
      <div v-else>
        <h1>Quizz {{quizz.quizz.name}}</h1>
        <small>{{ quizz.quizz.shortDescription }}</small>

        <div v-if="!endOfQuizz">
          <b-card v-for="(question,idx) in quizz.questions" :key="idx" class="mb-3">
            <strong>Question {{idx}}.</strong> <p v-html="question.text"></p>
            <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="answers[question.id]"
            >
              <div v-for="(option,idx) in question.options" :key="idx">
                <b-form-checkbox :value="option.optionName">{{ option.optionName }}
                  <p v-html="option.optionValue"></p>
                </b-form-checkbox>
                <br>
              </div>
            </b-form-checkbox-group>
          </b-card>
        </div>
        <div v-if="endOfQuizz">
          <h3>Thanks for your participation</h3>
          <p>Results will be announced in a few moments :) </p>
          <p>The Golang Paris Team</p>
        </div>
        <div v-else>
          <hr>
          <p>END of Quizz</p>
          <b-form-input v-model="name" placeholder="Enter your name" class="mb-2"></b-form-input>
          <Loader v-if="loadingPostAnswer"></Loader>
          <b-button variant="success" type="submit" block @click="postAnswers" :disabled="loadingPostAnswer">Submit your answers</b-button>
        </div>

      </div>
      <Footer></Footer>
    </b-container>

  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import Consts from "@/consts";
import WebService from "@/webservice";

const hljs = require('highlight.js/lib/core');  // require only the core library
// separately require languages
hljs.registerLanguage('go', require('highlight.js/lib/languages/go'));

export default {
  name: "QuizzView",
  components: { Loader, Footer,NavBar},
  props: {
    id: String,
  },
  data() {
    return {
      answers:{},
      titleMeta : "",
      descriptionMeta : "",
      loading: false,
      routeNames: Consts.ROUTE_NAMES,
      quizz: null,
      name: "",
      loadingPostAnswer: false,
      endOfQuizz: false,
    }
  },
  metaInfo () {
    return {
      title: this.titleMeta,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.titleMeta },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.titleMeta},
        { property: 'og:description', content: this.descriptionMeta },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]
    }
  },
  created() {
    window.scrollTo(0,0);
    this.fetchData()
  },
  methods: {
    postAnswers(e){
      e.preventDefault()
      if (this.name === ""){
        alert("Please input your name !")
        return
      }
      this.loadingPostAnswer = true
      let obj = {
        "answers": this.answers,
        "name": this.name,
        "quizzId": this.id,
      }
      WebService.postAnswers(obj).then(()=>{
        this.loadingPostAnswer = false
        this.endOfQuizz = true
      }).catch((err)=> {
        console.error(err)
        this.loadingPostAnswer = false
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    fetchData(){
      this.loading = true
      WebService.getQuizzById(this.id).then((res)=>{
        this.quizz = res.data
        res.data.questions.forEach((element) => {
          this.answers[element.id]=[]
        });
        this.titleMeta = this.quizz.quizz.name+"| Practical Go Lessons"
        this.descriptionMeta = "Quizz Practical Go Lessons"
        this.loading = false
        this.highlightCode()
      }).catch((err)=> {
        console.error(err)
        this.loading = false
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    highlightCode(){
      this.$nextTick(() => {
        document.querySelectorAll('code').forEach((el) => {
          hljs.highlightElement(el);
        });
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>